.tp-bullets.custom .tp-bullet {
  background: transparent;
}

.tp-bullets.custom div.tp-bullet {
  display: inline-block;
  width: 56px;
  height: 56px;
  margin-right: 30px;
  font-size: 24px;
  color: #323232;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.tp-bullets.custom div.tp-bullet:before {
  text-align: center;
  line-height: 27px;
  width: 27px;
  display: inline-block;
}

.tp-bullets.custom div.tp-bullet:after {
  content: "/";
  position: absolute;
  right: 0px;
  font-size: 30px;
  color: #323232;
}

.tp-bullets.custom div.tp-bullet:last-child:after {
  content: none;
}

.tp-bullets.custom div.tp-bullet.selected {
  background: transparent;
  color: dodgerblue;
  font-size: 36px;
}

.tp-bullets.custom div.tp-bullet.selected:hover,
.tp-bullets.custom div.tp-bullet:hover {
  background: transparent;
  color: dodgerblue;
}

.tp-bullets.custom div.tp-bullet:nth-child(1):before {
  content: "01";
}

.tp-bullets.custom div.tp-bullet:nth-child(2):before {
  content: "02";
}

.tp-bullets.custom div.tp-bullet:nth-child(3):before {
  content: "03";
}

.tp-bullets.custom div.tp-bullet:nth-child(4):before {
  content: "04";
}

.tp-bullets.custom div.tp-bullet:nth-child(5):before {
  content: "05";
}

.tp-bullets.custom div.tp-bullet:nth-child(6):before {
  content: "06";
}

.tp-bullets.custom div.tp-bullet:nth-child(7):before {
  content: "07";
}

.tp-bullets.custom div.tp-bullet:nth-child(8):before {
  content: "08";
}

.tp-bullets.custom div.tp-bullet:nth-child(9):before {
  content: "09";
}

.custom.tp-bullets:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box;
}

.custom .tp-bullet {
  width: 12px;
  height: 12px;
  position: absolute;
  background: #aaa;
  background: rgba(125, 125, 125, 0.5);
  cursor: pointer;
  box-sizing: content-box;
}

.custom .tp-bullet:hover,
.custom .tp-bullet.selected {
  background: #7d7d7d;
}

body,
html {
  font-family: "Open Sans", sans-serif;
}

body {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  margin-top: 0;
  margin-bottom: 15px;
}

.header-style-menu-center .mega-menu .menu-links > li.current-menu-item > a {
  color: dodgerblue;
}

.border-space {
  border-width: 1px;
  margin-top: 20px;
  margin-left: 80%;
  border-color: #323232;
  border-style: solid;
}

.border-space.border-space-dark {
  border-color: #323232;
}

.border-space.border-space-light {
  border-color: #fff;
}

.section-ptb {
  padding: 80px 0;
}

.section-pt {
  padding-top: 80px;
}

.section-pb {
  padding-bottom: 80px;
}

.section {
  margin-top: 80px;
  margin-bottom: 60px;
}

.section-1 .section-title h2 {
  font-size: 56px;
  line-height: 50px;
  text-align: right;
  font-weight: 700;
  word-break: break-word;
}

.section-1 .single_image-content {
  margin-top: -70px;
}

.section-1 .single_image-content p {
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
}

.section-1 .single_image-content p {
  font-size: 24px;
  color: #fff;
  margin-bottom: 0;
}

.section-1 .single_image-content p.text-dark {
  color: #323232 !important;
}

.section-1 .single_image-content .border-space {
  margin-left: 34px;
  margin-top: 12px;
}

.ciyashop_button_wrapper {
  display: inline-block;
}

.ciyashop_button_wrapper .ciyashop_button_size_medium a {
  font-size: 14px;
  padding: 12px 26px;
  line-height: 18px;
  color: #fff;
  background: #323232;
  display: inline-block;
}

.ciyashop_button_wrapper .ciyashop_button_size_medium a:hover {
  background: dodgerblue;
}

.ciyashop_banner {
  position: relative;
  overflow: hidden;
}

.ciyashop_banner.ciyashop_banner-3 .ciyashop_banner-content-wrapper {
  align-items: flex-start !important;
}

.ciyashop_banner.ciyashop_banner-3 .ciyashop_banner-text-wrap .ciyashop_banner-text h2 {
  font-size: 105px !important;
  line-height: normal !important;
  font-weight: 500 !important;
  font-family: "Open Sans", sans-serif;
}

.ciyashop_banner.ciyashop_banner-3 .ciyashop_banner-text-wrap .ciyashop_banner-text h3 {
  font-size: 28px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
}

.ciyashop_banner.ciyashop_banner-3 .ciyashop_banner-btn-wrap .ciyashop_banner-btn {
  line-height: 44px;
  padding: 0 25px !important;
  font-weight: 500 !important;
  color: #fff !important;
  background-color: dodgerblue;
  border: none !important;
}

.ciyashop_banner.ciyashop_banner-3 .ciyashop_banner-btn-wrap .ciyashop_banner-btn:hover {
  background-color: #323232;
}

.ciyashop_banner .ciyashop_banner-content {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 10;
  padding: 25px;
  -webkit-transition: background .5s ease;
  -o-transition: background .5s ease;
  transition: background .5s ease;
}

.ciyashop_banner .ciyashop_banner-content-1 {
  padding-left: 48%;
}

.ciyashop_banner .ciyashop_banner-content-2 {
  padding-right: 48%;
  text-align: right;
}

.ciyashop_banner .ciyashop_banner-content .ciyashop_banner-content-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.ciyashop_banner .ciyashop_banner-content.ciyashop_banner-content-vbottom {
  top: inherit;
}

.ciyashop_banner .ciyashop_banner-content.ciyashop_banner-content-vbottom .ciyashop_banner-content-wrapper {
  justify-content: center;
}

.ciyashop_banner .ciyashop_banner-content .ciyashop_banner-content-wrapper .ciyashop_banner-content-inner-wrapper .ciyashop_banner-label-wrap .ciyashop_banner-label {
  font-size: 16px;
  color: #ffffff;
  background-color: #777fff;
  line-height: 20px;
  font-weight: 700;
  padding: 7px 15px;
  margin: 0;
  margin-bottom: 10px;
  display: inline-block;
}

.ciyashop_banner .ciyashop_banner-content .ciyashop_banner-content-wrapper .ciyashop_banner-content-inner-wrapper .ciyashop_banner-label-wrap .ciyashop_banner-label.ciyashop_banner-lanel-bg-yellow {
  background-color: #fff2d5;
}

.ciyashop_banner .ciyashop_banner-content .ciyashop_banner-content-wrapper .ciyashop_banner-content-inner-wrapper .ciyashop_banner-text-wrap .ciyashop_banner-text h2 {
  font-size: 32px;
  color: #777fff;
  font-weight: 700;
  text-transform: capitalize;
  line-height: normal;
  margin: 0;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
}

.ciyashop_banner .ciyashop_banner-content .ciyashop_banner-content-wrapper .ciyashop_banner-content-inner-wrapper .ciyashop_banner-btn-wrap {
  position: relative;
  z-index: 9;
  margin-top: 15px;
}

.ciyashop_banner .ciyashop_banner-content .ciyashop_banner-content-wrapper .ciyashop_banner-content-inner-wrapper .ciyashop_banner-btn-wrap .ciyashop_banner-btn {
  font-weight: 600;
  border-bottom: 0px solid;
  padding-left: 0;
  padding-right: 0;
  color: #969696;
  display: inline-block;
  font-size: 14px;
}

.ciyashop_banner .ciyashop_banner-content .ciyashop_banner-content-wrapper .ciyashop_banner-content-inner-wrapper .ciyashop_banner-btn-wrap .ciyashop_banner-btn:hover {
  color: dodgerblue;
}

.ciyashop_banner .countdown {
  padding: 0;
  margin: 0 0 0 !important;
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.ciyashop_banner .countdown li {
  background: 0 0;
  padding: 0;
  margin: 0;
  margin-right: 10px;
  border: none;
  display: inline-block;
  line-height: 20px;
  text-align: center;
  min-width: 50px;
}

.ciyashop_banner .countdown li span {
  display: block;
  font-size: 28px;
  color: #777fff;
  line-height: 40px;
  padding-bottom: 5px;
  font-weight: 600;
}

.ciyashop_banner .countdown li p {
  background: #323232;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 0 10px;
  text-transform: uppercase;
}

.ciyashop_banner.ciyashop_banner-style-deal-1 .ciyashop_banner-content {
  padding: 120px 0;
  position: relative;
}

.section-2 .ciyashop_banner-content-wrapper {
  justify-content: center;
  text-align: center;
}

.section-2 .ciyashop_banner-content-wrapper .ciyashop_banner-label {
  font-size: 28px !important;
  line-height: 38px !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 400 !important;
}

.section-2 .ciyashop_banner-content-wrapper .ciyashop_banner-text h2 {
  margin-top: 30px;
  font-size: 62px;
  color: #323232;
  font-style: normal;
  font-weight: 600;
  line-height: 82px;
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
}

.section-2 .ciyashop_banner-content-wrapper .ciyashop_banner-btn {
  font-size: 14px;
  line-height: 44px;
  padding: 0 25px !important;
  color: #323232 !important;
  background-color: #fff;
  border: none !important;
}

.section-2 .ciyashop_banner-content-wrapper .ciyashop_banner-btn:hover {
  background-color: #323232;
  color: #fff !important;
}

.section-3 .ciyashop_button_wrapper {
  margin-top: 0;
}

.section-3 .ciyashop_button_wrapper a.inline_hover {
  position: relative;
  background: transparent;
  color: #323232;
  font-size: 14px;
  padding: 3px 0;
  line-height: 22px;
}

.section-3 .ciyashop_button_wrapper a.inline_hover:after {
  content: "";
  width: 100%;
  border-bottom: 2px solid;
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.section-3 .ciyashop_button_wrapper a.inline_hover:hover {
  background: transparent;
}

.section-3 .ciyashop_button_wrapper a.inline_hover:hover:after {
  width: 40%;
}

.banner-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-4 .input-area {
  background-color: #fff;
  padding-left: 0;
  border-radius: 3px;
}

.newsletter-wrapper .newsletter-title {
  line-height: 38px;
  font-weight: 700;
  color: #000fff;
}

.position-a {
  position: absolute;
}
